.content {
    /*border: 1px solid rgb(128, 123, 123);*/
    padding: 0.5rem;
    border-radius: 5px;
}

.content-parent {
    height: 0px;
    overflow: hidden;
    transition: height ease 0.9s;
}

.content-show {
    height: 300px;
}

.section {
    width: 50%;
    height: 100%;
}

video {
    width: 100%;
    height: 56.25%;
    float: right;
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0
}

button {
    background-color: navy;
    color: white;
    border-color: white;
}