.App {
  background-color: #48C7FF;
  filter: brightness(90%);
  color: white;
  min-height: 100vh;
}

/*.navbar-dark .navbar-toggler-icon {*/
/*}*/

.navItems {
  position: absolute;
  padding-top: 18px;
  font-size: 25px;
}

.homepage-title {
  position: absolute;
  float: right;
  top: 80px;
  right: 0;
  font-family: Oxygen;
  font-weight: bolder;
  text-decoration: underline;
}

.cs-banner {
  float: right;
  position: relative;
  display: flex;
  opacity: .85;
  paddingTop: 60px;
}

.portrait {
  border-width: 1px;
  border-radius: 150px;
  padding-top: 60px;
}

.introduction-title {
  position: absolute;
  font-family: "Bodoni 72";
  font-weight: bold;
}

.introduction-paragraph {
  position: absolute;
  color: black;
  font-family: fantasy;
  font-style: italic;
  line-height: 2;
}

.email-icon {
  position: absolute;
}

.github-icon {
  position: absolute;
}

.linkedin-icon {
  position: absolute;
}

.video-production {
  padding-top: 60px;
}

a:link {
  color: white;
}

a:visited {
  color: orangered;
}

.experience {
  padding-top: 60px;
}

.playVideo {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  width: 100%;
}

.responsive-iframe {
  overflow: hidden;
  border: 0;
  align-self: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.rewind-header {
  color: white;
  text-shadow: 1px 1px 2px black;
  font-size: xx-large;
  font-family: Helvetica;
}

.rewind-description {
  text-shadow: 1px 1px 2px black;
  font-size: small;
  font-family: cursive;
  display: inline-block;
}

.overwatch{
  background-image: url('images/genji_potg_intro.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 60px;
  width: 100vw;
  height: 100vh
}

#rewind {
  color: black;
}

/*Mobile*/
/* iPhone 13 Pro Max | 2778x1284 pixels at 458ppi | 429w x 926h */
@media screen and (max-width: 429px) and (max-height: 926px) {
  .homepage-title {
    top: 10%;
    display: flex;
    font-size: 15px;
    padding-right: 2%;
  }
  .cs-banner {
    top: 0;
    max-width: 100%;
    width: 50%;
    object-fit: scale-down;
    clip-path: inset(160px 0 160px round 10px 0 0 10px);
  }
  .portrait {
    width: 50%;
    height: 390px;
  }
  .introduction-title {
    left: 5%;
    top: 42%;
    font-size: 30px;
  }
  .introduction-paragraph {
    font-family: Arial;
    padding-left: 5%;
    top: 47%;
    font-size: 18px;
  }
  .email-icon {
    left: 50%;
    top: 30%;
    width: 45px;
    height: 30px;
  }
  .github-icon {
    left: 70%;
    top: 29%;
    width: 44px;
    height: 45px;
  }
  .linkedin-icon {
    right: 0;
    top: 29%;
    width: 44px;
    height: 45px;
  }
}

/* iPhone 13 mini | 2340x1080 pixels at 476ppi | 375w x 812h */
@media screen and (max-width: 390px) and (max-height: 844px) {
  .homepage-title {
    top: 10%;
    display: flex;
    font-size: 14px;
    padding-right: 2%;
  }
  .cs-banner {
    top: 0;
    max-width: 100%;
    width: 50%;
    object-fit: scale-down;
    clip-path: inset(160px 0 160px round 10px 0 0 10px);
  }
  .portrait {
    width: 50%;
    height: 370px;
  }
  .introduction-title {
    left: 5%;
    top: 49%;
    font-size: 30px;
  }
  .introduction-paragraph {
    font-family: Arial;
    padding-left: 5%;
    top: 55%;
    font-size: 18px;
  }
  .email-icon {
    left: 48%;
    top: 37%;
    width: 45px;
    height: 30px;
  }
  .github-icon {
    left: 69%;
    top: 36%;
    width: 44px;
    height: 45px;
  }
  .linkedin-icon {
    right: 1%;
    top: 36%;
    width: 44px;
    height: 45px;
  }
}

/* iPhone SE | 375w x 667h */
@media screen and (max-width: 375px) and (max-height: 667px) {
  .homepage-title {
    top: 10%;
    display: flex;
    font-size: 14px;
    padding-right: 2%;
  }
  .cs-banner {
    top: 0;
    max-width: 100%;
    width: 50%;
    object-fit: scale-down;
    clip-path: inset(160px 0 160px round 10px 0 0 10px);
  }
  .portrait {
    width: 50%;
    height: 370px;
  }
  .introduction-title {
    left: 5%;
    top: 55%;
    font-size: 30px;
  }
  .introduction-paragraph {
    font-family: Arial;
    padding-left: 5%;
    top: 62%;
    font-size: 18px;
  }
  .email-icon {
    left: 50%;
    top: 37%;
    width: 40px;
    height: 25px;
  }
  .github-icon {
    left: 70%;
    top: 36%;
    width: 39px;
    height: 40px;
  }
  .linkedin-icon {
    right: 0;
    top: 36%;
    width: 39px;
    height: 40px;
  }
}

/* Tablet */
/* iPad air */
@media all and (device-width: 820px) and (device-height: 1180px) and (orientation:portrait) {
  div.homepage-title{
    font-size: 25px;
  }
  .homepage{
    position: relative;
  }
  .homepage-title {
    top: 8%;
    left: 50%;
    width: 50%;
  }
  .cs-banner {
    top: 0;
    max-width: 100%;
    width: 60%;
    object-fit: scale-down;
    clip-path: inset(160px 0 160px round 10px 0 0 10px);
  }
  .portrait {
    width: 40%;
    height: 500px;
  }
  .introduction-title {
    position: static;
    left: 0;
    top: 70%;
    font-size: 30px;
  }
  .introduction-paragraph {
    position: static;
    font-family: Arial;
    padding-left: 5%;
    top: 69%;
    font-size: 18px;
  }
  .email-icon {
    left: 70%;
    top: 32.5%;
    width: 40px;
    height: 28px;
  }
  .github-icon {
    right: 10%;
    top: 32%;
    width: 39px;
    height: 40px;
  }
  .linkedin-icon {
    right: 0;
    top: 32%;
    width: 39px;
    height: 40px;
  }
}

@media all and (device-width: 1180px) and (device-height: 820px) and (orientation:landscape) {
  div.homepage-title{
    font-size: 25px;
  }
  .homepage{
    position: relative;
  }
  .homepage-title {
    position: absolute;
    top: 10%;
    left: 50%;
    width: 50%;
  }
  .cs-banner {
    position: absolute;
    left: 40%;
    top: 0;
    max-width: 100%;
    width: 60%;
    object-fit: scale-down;
    clip-path: inset(160px 0 160px round 10px 0 0 10px);
  }
  .portrait {
    width: 40%;
    height: 50%;
  }
  .introduction-title {
    position: unset;
    font-size: 30px;
  }
  .introduction-paragraph {
    position: static;
    font-family: Arial;
    padding-left: 5%;
    top: 69%;
    font-size: 18px;
  }
  .email-icon {
    top: 400px;
    width: 40px;
    height: 28px;
  }
  .github-icon {
    top: 400px;
    left: 70%;
    width: 39px;
    height: 40px;
  }
  .linkedin-icon {
    top: 400px;
    width: 39px;
    height: 40px;
  }
}

/*Desktop*/
@media screen and (min-width: 601px) {
  .homepage-title {
    font-size: 40px;
    padding-right: 10%;
  }
  .cs-banner {
    clip-path: inset(150px 0 20px round 10px 0 0 10px);
  }
  .introduction-title {
    padding-left: 42%;
    top: 380px;
    font-size: 45px;
  }
  .introduction-paragraph {
    padding-left: 42%;
    top: 450px;
    font-size: 20px;
  }
  .email-icon {
    left: 42%;
    bottom: 7%;
  }
  .github-icon {
    left: 63%;
    bottom: 6%;
  }
  .linkedin-icon {
    right: 3%;
    bottom: 6%;
  }
}
